<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("productionUnit.title") }}</h1>

    <production-unit-list />
  </article>
</template>

<script>
import ProductionUnitList from "@/components/productionunit/ProductionUnitList";

export default {
  components: {
    ProductionUnitList,
  },
};
</script>
